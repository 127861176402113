import FF from "../../components/forms/FormField.module.css";
import TF from "../../components/forms/TextField.module.css";

import { Navigate } from "@tanstack/react-router";
import { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "react-aria-components";
import { Controller, type SubmitHandler, useForm, useFormContext } from "react-hook-form";
import { ContentPane } from "../../components/ContentPane.tsx";
import { HeroBlock } from "../../components/HeroBlock.tsx";
import { NoticeBlock } from "../../components/NoticeBlock.tsx";
import { Separator } from "../../components/Separator.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { CreateAndAssignIpField } from "../../components/forms/CreateAndAssignIpField.tsx";
import { CreateFormAction } from "../../components/forms/CreateFormAction.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { IconRadio } from "../../components/forms/IconRadio.tsx";
import { MonthlyCostElement, MonthlyCostField } from "../../components/forms/MonthlyCostField.tsx";
import { RadioGroupDiv } from "../../components/forms/RadioGroupDiv.tsx";
import { WHookForm } from "../../components/forms/WHookForm.tsx";
import { WRadioGroup } from "../../components/forms/WRadioGroup.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { CreateAnother, LoadingProgress } from "../../components/loading/Loading.tsx";
import { useConfig } from "../../config.ts";
import { cn } from "../../utils/classNames.ts";
import { castSetter } from "../../utils/getField.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useOnce } from "../../utils/react/useOnce.ts";
import { useGenerateUuid } from "../../utils/useGenerateUuid.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { BillingAccountField } from "../billing/forms/BillingAccountField.tsx";
import { LocationFieldCustom } from "../location/LocationField.tsx";
import { useLocationsForType } from "../location/query.ts";
import { VpcNetworkSelect } from "../network/VpcNetworkSelect.tsx";
import { allHostPoolsQueryAtom } from "../pools/query.ts";
import { PricingModal } from "../pricing/PricingModal.tsx";
import { pricesAtom } from "../pricing/query.ts";
import { getVmCreatePrice } from "../pricing/resourcePricing.ts";
import { VmCloneHeaderBlock } from "./ModalHeaderBlock.tsx";
import { ParamsTextField } from "./ParamsTextField.tsx";
import { type VmPriceFieldsOmitSize, VmSizeSelect } from "./VmSizeSelect.tsx";
import { vmLink } from "./links.ts";
import { OsVersionSelectCustom } from "./os/OsVersionSelect.tsx";
import { type ComputeImageType, getResourceTypeImages, osIsWindows } from "./os/os.ts";
import { getPoolsForLocation } from "./pool/pool.ts";
import { type ItemType, type VmCreateInputs, VmCreateViewModel } from "./vmCreateViewModel.tsx";
import { availableImagesAtom } from "./vmImagesQuery.ts";
import { usePackages } from "./vmPackages.ts";
import { parametersByNameAtom } from "./vmParametersQuery.ts";
import { VM_CREATE_TIMER, vmCreateMutation } from "./vmQuery.ts";
import { getSizeParams, sizeToVmPriceFields } from "./vmSizeSelectUtils.ts";

const types = [
    {
        id: "virtual_machine",
        title: "Virtual Machine",
        icon: "jp-virtual-machine-icon",
        description: "",
    },
    {
        id: "app_catalog",
        title: "App Catalog",
        icon: "jp-icon-appcatalog",
        description: "",
    },
] as const satisfies ItemType[];

/* eslint-disable @typescript-eslint/no-unused-vars  */

interface VmCreateProps {
    inputs?: Partial<VmCreateInputs>;
    previousRequestUuid?: string;
    onChangeInputs?: (inputs: VmCreateInputs) => void;
}

function getUsernameForWindows() {
    return "administrator";
}

export function VmCreate(props: VmCreateProps) {
    return import.meta.env.DEV ? <VmCreateDebugger {...props} /> : <VmCreateForm {...props} />;
}

export function VmCreateForm({ inputs, previousRequestUuid, onChangeInputs }: VmCreateProps) {
    //#region Hooks
    const params = useSuspenseQueryAtom(parametersByNameAtom);
    const poolsMap = useSuspenseQueryAtom(allHostPoolsQueryAtom);
    const pools = [...poolsMap.values()];

    const allImages = useSuspenseQueryAtom(availableImagesAtom);

    const allSizePackages = usePackages();

    const { vmAppsEnabled } = useConfig();
    const availableTypes = vmAppsEnabled ? types : types.filter((i) => i.id !== "app_catalog");

    function getImages(type: ComputeImageType) {
        return getResourceTypeImages(allImages, type);
    }

    const mutation = useStandardMutation(vmCreateMutation);

    const locations = useLocationsForType("virtual_machine");

    const requestUuid = useGenerateUuid(undefined);

    const viewModel = useOnce(() => {
        const sizeParams = getSizeParams(params);
        return new VmCreateViewModel(availableTypes, allImages, locations, pools, allSizePackages, sizeParams, inputs);
    });

    const defaultValues = useMemo(() => viewModel.getDefaultValues(), [viewModel]);

    /// Form
    const form = useForm<VmCreateInputs>({
        disabled: mutation.isPending,

        mode: "onBlur",
        reValidateMode: "onBlur",

        defaultValues,
    });

    const { watch, control, setValue, getValues } = form;

    viewModel.attachGetterAndSetter(getValues, castSetter(setValue));

    useEffect(() => {
        const subscription = watch((_value, _info) => {
            onChangeInputs?.(getValues()); // for form debugger
        });
        return () => subscription.unsubscribe();
    }, [watch, getValues, onChangeInputs]);

    //#endregion Hooks

    if (mutation.isSuccess) {
        // We can navigate to the VM view (even though the invalidated list query has not necessarily finished yet)
        // because of the optimistic update in the mutation's `onSuccess` handler.
        return <Navigate {...vmLink(mutation.data)} />;
    }

    const onSubmit: SubmitHandler<VmCreateInputs> = async (inputs) => {
        const {
            location,
            os,
            size: { isCustom, ...sizeComponents },
            typeId,
            username,
            size_ranges,
            ...body
        } = inputs;
        await mutation.mutateAsync({
            requestUuid,
            previousRequestUuid,
            inputs,
            body: {
                // XXX: This is copied from the old UI, but perhaps we should make this an actual field?
                description: body.name.trim(),
                ...body,
                ...os,
                ...sizeComponents,
                username: osIsWindows(os) ? getUsernameForWindows() : username,
            },
            location,
        });
    };

    const usernameParams = params.get("username")!;
    const passwordParams = params.get("password")!;
    const nameParams = params.get("name")!;
    const publicKeyParams = params.get("public_key")!;

    const isWindows = osIsWindows(watch("os"));

    const locationPools = getPoolsForLocation(pools, watch("location"));

    const locationInfo = locations.locations.find((l) => l.slug === watch("location"))!;
    const vpcsEnabled = !locationInfo.mikrotik;

    const isFromSnapshot = !!watch("source_replica");

    const createFields = {
        ...watch("os"),
        location: watch("location"),
        designated_pool_uuid: watch("designated_pool_uuid"),
    } satisfies VmPriceFieldsOmitSize;

    return (
        <WHookForm
            form={form}
            onSubmit={onSubmit}
            loader={
                mutation.isPending && (
                    <LoadingProgress name={form.getValues().name} type="virtual_machine" lifeTime={VM_CREATE_TIMER}>
                        <CreateAnother type="virtual_machine" />
                    </LoadingProgress>
                )
            }
        >
            <CreateHero />

            {!isFromSnapshot ? (
                <>
                    <Controller
                        control={control}
                        name="typeId"
                        render={({ field }) => (
                            <WRadioGroup
                                isDisabled={isFromSnapshot}
                                label="Type"
                                isRequired
                                value={field.value}
                                onChange={viewModel.onChangeType}
                            >
                                {availableTypes.map((item) => (
                                    <IconRadio key={item.id} {...item} />
                                ))}
                            </WRadioGroup>
                        )}
                    />

                    <Separator />

                    <FormField wide label="OS" isRequired>
                        <RadioGroupDiv>
                            <Controller
                                control={control}
                                name="os"
                                render={({ field }) => (
                                    <OsVersionSelectCustom
                                        items={getImages(watch("typeId"))}
                                        value={field.value}
                                        onChange={viewModel.onChangeOs}
                                    />
                                )}
                            />
                        </RadioGroupDiv>
                    </FormField>

                    {locations.showLocations && (
                        <Controller
                            control={control}
                            name="location"
                            render={({ field }) => (
                                <LocationFieldCustom
                                    value={field.value}
                                    onChange={viewModel.onLocationChange}
                                    locations={locations}
                                />
                            )}
                        />
                    )}
                </>
            ) : (
                <FormField wide label="From" isRequired>
                    <div className="pb-2">
                        <VmCloneHeaderBlock inputs={inputs} />
                    </div>

                    <NoticeBlock icon="jp-warning-icon">
                        <b className="uppercase">Warning</b>
                        <br />
                        Only the boot disk will be created! This may cause the OS to not boot properly if the
                        configuration in the OS of source snapshot requires additional disks to be present.
                    </NoticeBlock>
                </FormField>
            )}

            <Separator />

            {!isFromSnapshot && locationPools.length ? (
                <Controller
                    control={control}
                    name="designated_pool_uuid"
                    render={({ field }) => (
                        <WRadioGroup
                            wide
                            label="Server class"
                            isRequired
                            value={field.value}
                            onChange={viewModel.onDesignatedPoolChange}
                        >
                            {locationPools.map((item) => (
                                <IconRadio
                                    wide
                                    icon="jp-cluster-icon"
                                    id={item.uuid}
                                    title={item.name}
                                    key={item.uuid}
                                    desc={item.description}
                                    {...item}
                                />
                            ))}
                        </WRadioGroup>
                    )}
                />
            ) : null}

            <FormField wide label="Size" isRequired>
                <RadioGroupDiv>
                    <Controller
                        control={control}
                        name="size"
                        render={() => (
                            <VmSizeSelect
                                vmData={createFields}
                                value={watch("size")}
                                sliderRanges={watch("size_ranges")}
                                onChange={viewModel.onSizeChange}
                            />
                        )}
                    />
                </RadioGroupDiv>
            </FormField>

            <Separator />

            <CreateAndAssignIpField<VmCreateInputs>
                name="reserve_public_ip"
                control={control}
                location={watch("location")}
            />

            {vpcsEnabled && (
                <FormField
                    isRequired
                    label="VPC network"
                    description="Choose a VPC network for your resource from already existing one or create a new private network."
                >
                    <Controller
                        control={control}
                        name="network_uuid"
                        render={({ field }) => (
                            <VpcNetworkSelect
                                location={watch("location")}
                                isDisabled={field.disabled}
                                valueKey={field.value ?? null}
                                onChange={(_vpc, key) => field.onChange(key)}
                            />
                        )}
                    />
                </FormField>
            )}

            <Separator />

            {isWindows || isFromSnapshot ? (
                <FormField isDisabled label="Username">
                    <Input
                        readOnly
                        value={isFromSnapshot ? inputs?.username : getUsernameForWindows()}
                        className={cn(FF.FormFieldInput, TF.Input)}
                    />
                </FormField>
            ) : (
                <ParamsTextField
                    params={usernameParams}
                    autoComplete="username"
                    label="Username"
                    isReadOnly={isWindows}
                >
                    <p className="color-warning font-size-small pt-1">
                        SSH might not be available for root user due to operating system initial setup. Please use
                        virtual console to access the system and make the SSH server config changes according to your
                        needs.
                    </p>
                </ParamsTextField>
            )}
            {!isFromSnapshot && (
                <>
                    <ParamsTextField
                        params={passwordParams}
                        type="password"
                        autoComplete="new-password"
                        label="Password"
                    />
                    <ParamsTextField
                        params={{ ...passwordParams, parameter: "password_confirm" }}
                        type="password"
                        autoComplete="new-password"
                        label="Confirm password"
                        registerOptions={{
                            deps: ["password"],
                            validate: (value, { password }) => {
                                return value === password || "Passwords do not match";
                            },
                        }}
                    />
                </>
            )}

            {!isWindows && !isFromSnapshot && (
                <ParamsTextField
                    params={publicKeyParams}
                    multilineRows={5}
                    label="SSH Public Key"
                    registerOptions={{
                        validate: (value, { public_key }) => {
                            if (publicKeyParams.constraint === "regexp" && publicKeyParams.expression && !isWindows) {
                                const regexp = new RegExp(publicKeyParams.expression);
                                if (public_key && !regexp.test(public_key))
                                    return "SSH key does not match required pattern";
                            }
                            return true;
                        },
                    }}
                />
            )}

            <ParamsTextField params={nameParams} label="Resource name" />

            <Separator />

            <VmMonthlyCostField
                createFields={{
                    ...sizeToVmPriceFields(watch("size"), true),
                    ...createFields,
                }}
            />

            <BillingAccountField resourceType="virtual_machine" isRequired />

            <Separator />

            <CreateFormAction resourceType="virtual_machine" />
        </WHookForm>
    );
}

function VmMonthlyCostField({ createFields }: { createFields: VmPriceFieldsOmitSize }) {
    //#region Hooks
    const { watch } = useFormContext<VmCreateInputs>();
    const priceList = useSuspenseQueryAtom(pricesAtom);
    //#endregion

    const price = getVmCreatePrice(
        priceList,
        { ...createFields, ...sizeToVmPriceFields(watch("size"), true) },
        watch("reserve_public_ip"),
    );

    return (
        <MonthlyCostField
            description={
                <>
                    The prices is approximate, actual cost of your resource will be calculated based on your actual
                    hourly usage.{" "}
                    <PricingModal
                        defaultLocation={createFields.location}
                        defaultHostPool={createFields.designated_pool_uuid}
                    >
                        Please see how the cost of your resource is calculated.
                    </PricingModal>
                </>
            }
        >
            <MonthlyCostElement price={price} />
        </MonthlyCostField>
    );
}

function CreateHero() {
    return (
        <HeroBlock title="Create New Resource" icon="jp-compute-cloud-icon">
            <p className="text-muted">
                Create a new Virtual Machine or choose something from the App Catalog to get your services running in
                minutes.
            </p>
        </HeroBlock>
    );
}

/** Debugger to check if re-loading the form works (this is used to eg. retry creating a VM from the error notification) */
function VmCreateDebugger(props: VmCreateProps) {
    const currentInputsRef = useRef(props.inputs);
    const heldInputsRef = useRef(props.inputs);

    const [resetKey, setResetKey] = useState(0);
    return (
        <>
            <VmCreateForm
                key={resetKey}
                {...props}
                onChangeInputs={(newInputs) => {
                    currentInputsRef.current = newInputs;
                }}
                inputs={heldInputsRef.current}
            />
            <ContentPane className="HStack gap-1">
                <MaskIcon className="size-0.875rem i-lucide:bug color-muted" />
                <WButton
                    label="Reload form"
                    icon="i-lucide:refresh-ccw"
                    action={() => {
                        console.log("VmCreate: Reloading form");
                        heldInputsRef.current = currentInputsRef.current;
                        setResetKey((k) => k + 1);
                    }}
                />
                <WButton
                    label="Log inputs"
                    action={() => {
                        console.log(currentInputsRef.current);
                    }}
                />
            </ContentPane>
        </>
    );
}
